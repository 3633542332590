import React from "react";
import Layout from "../components/layout/Layout";
import { Helmet } from "react-helmet";

export default function TermsAndConditions() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms & Conditions | Baseline</title>
        <meta
          name="description"
          content="Baseline is an efficient and cost effective resource planning tool that builds and manages the optimal teams for your projects."
        />
        <meta
          name="keywords"
          content="Saas, Cheap, Time Saving ,Team, Team Management,Mental Health,Best Team Management Tool, Best Project Management Tool,Easy to use Team Management Tool,Easy to use Project Management Tool,Monitoring profitability,Ways to improve profitability,Time management,How to improve mental health,How to build a successful team,Staffing tool,Utilization tool,HR tool or HR Software,Resource allocation,People first team creation,Hybrid workforce,Visualize department utilization,Collaborative team building,Hard and Soft skill data talent search,User friendly team management,Future workforce,Creating happy and profitable teams,How can I see burnout in my team,"
        />
        <link rel="canonical" href="https://baselineteams.com" />
      </Helmet>
      <Layout>
        <div className="bg-white py-16 px-4 text-black lg:px-64">
          <h1 className="justify-center text-center text-3xl font-bold mt-8 mb-16">
            Baseline Terms & Conditions
          </h1>
          <div className="text-base my-4">Effective date: January 2021</div>
          <div className="my-8">
            <h2 className="text-xl font-semibold my-8">
              1. Customer Terms Perspective
            </h2>
            <p>
              These customer terms of service (the ‘Customer Terms’) govern your
              access and use of our online workplace productivity and employee
              success platform (the “Services”). Please read them carefully. If
              you are a customer, as defined below, these Customer terms govern
              your access to and use of our Services. If you are invited to an
              existing team workspace setup by a Customer, user terms of service
              (“User Terms”) that govern your access to and use of the Services
              can be found after this section below. Thanks for using Baseline.
            </p>
            <br />
            <p className="italic mt-4">
              These ‘Customer Terms’ form part of a binding ‘Contract’
            </p>
            <br />
            <p>
              These Customer Terms (or, if applicable, your written agreement
              with us) and any Order Form(s) (defined below) together form a
              binding ‘Contract’ between a Customer and us. ‘We’, ‘our’ and ‘us’
              refer to the Baseline entity.
            </p>
            <br />
            <p className="italic mt-4">
              Your agreement on behalf of the ‘Customer’
            </p>
            <br />
            <p>
              If you purchase subscription(s), create a workspace, invite users
              to that workspace, or use or allow use of that workspace after
              being notified of a change to these Customer Terms, you
              acknowledge your understanding of the then-current Contract and
              agree to the Contract on behalf of the Customer. Please make sure
              that you have the necessary authority to enter into the Contract
              on behalf of the Customer before proceeding.
            </p>
            <div className="my-4">
              <h3 className="text-xl my-4">1.1. Who is the ‘Customer’?</h3>
              <p>
                The ‘Customer’ is the organisation that you represent in
                agreeing to the Contract. If your workspace is being set up by
                someone who is not formally affiliated with an organisation, the
                Customer is the individual creating the workspace. For example,
                if you signed up using a personal email address and invited a
                couple of friends to work on a new collaboration project idea,
                but haven't formed a company yet, you are the Customer.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">
                1.2. Signing up using a corporate email domain
              </h3>
              <p>
                If you signed up for a subscription using your corporate email
                domain, your organisation is the Customer, and the Customer can
                modify and reassign roles on your workspace (including your
                role) and otherwise exercise its rights under the Contract. If
                the Customer elects to replace you as the representative with
                ultimate authority for the workspace, we will provide you with
                notice following such election and you agree to take any actions
                reasonably requested by us or the Customer to facilitate the
                transfer of authority to a new representative of the Customer.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">
                1.3. Authorised User by the Customer
              </h3>
              <p>
                Individuals authorised by the Customer to access the Services
                (an ‘Authorised User’) may submit content or information to the
                Services, such as messages or files (‘Customer Data’), and the
                Customer may exclusively provide us with instructions on what to
                do with it.
                <br />
                <br />
                The Customer will (a) inform Authorised Users of all Customer
                policies and practices that are relevant to their use of the
                Services and of any settings that may affect the processing of
                Customer Data, and (b) ensure that the transfer and processing
                of Customer Data under the Contract are lawful.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">1.4. Subscriptions</h3>
              <p>
                A subscription allows an Authorised User to access the Services.
                No matter what role they are in, a subscription is required for
                each Authorised User. A subscription may be procured through the
                Services interface. Each Authorised User must agree to the User
                Terms to activate their subscription. Subscriptions commence
                when we make them available to the Customer and continue for the
                term specified in the Services ‘checkout’ interface or in the
                Order Form, as applicable. Each subscription is for a single
                Authorised User for a specified term and is personal to that
                Authorised User. We sometimes enter into other kinds of ordering
                arrangements, but that would need to be spelled out and agreed
                to in an Order Form. During an active subscription term, adding
                more subscriptions is fairly easy. Unless the Order Form says
                otherwise, Customer may purchase more subscriptions at the same
                price stated in the Order Form, and all will terminate on the
                same date.
                <br />
                <br />
                Pricing for the subscriptions is set forth here:
                https://www.baselineteams.com/pricing. You will be billed in
                advance on a recurring and periodic basis (“Billing Cycle”).
                Billing Cycles are set on a monthly or yearly basis. Whether you
                are using monthly or yearly billing, your account may be charged
                on a monthly basis.
                <br />
                <br />
                <span className="italic">Auto-Renewal: </span>You agree that at
                the end of each Billing Cycle, your Subscription will
                automatically renew and your payment method for such
                Subscription will automatically be charged at the start of each
                new Subscription period for the fees and taxes applicable to
                that Billing Cycle, under the same conditions as the prior
                Billing Cycle unless you cancel it or Baseline cancels it.
                <br />
                <br />
                A valid payment method, including credit card, is required to
                process the payment for your Subscription. You must provide
                Baseline with accurate and complete billing information
                including full name, address, state, zip code, telephone number,
                and valid payment method information. By submitting such payment
                information, you automatically authorize Baseline to charge all
                Subscription fees incurred through your account to any such
                payment instruments. Should automatic billing fail to occur for
                any reason, Baseline may attempt to charge the credit card on
                file one or more times. Should payment continue to fail, the
                Subscription will be cancelled.
                <br />
                <br />
                You may cancel your Subscription either through your online
                account management page or by contacting Baseline customer
                support team at hello@baselineteams.com.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">1.5. Purchasing decisions</h3>
              <p>
                We may share information about our future product subscriptions,
                because we like transparency. Our public statements about those
                product subscriptions are an expression of intent, but do not
                rely on them when making a purchase. If the Customer decides to
                buy our Services, that decision should be based on the
                functionality or features that we have made available today and
                not on the delivery of any future functionality or features.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">1.6. Privacy Policy </h3>
              <p>
                Please refer to the Privacy Policy to for more information on
                how we collect and use data relating to the use and performance
                of our websites and products.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">1.7. Use of the Services</h3>
              <p>
                The Customer must comply with the Contract and ensure that its
                Authorised Users comply with the Contract and the User Terms. We
                may review conduct for compliance purposes, but we have no
                obligation to do so. We aren't responsible for the content of
                any Customer Data or the way that the Customer or its Authorised
                Users choose to use the Services to store or process any
                Customer Data. The Services are not intended for and should not
                be used by anyone under the age of 16. The Customer must ensure
                that all Authorised Users are over 16 years of age. The Customer
                is solely responsible for providing high-speed Internet service
                for itself and its Authorised Users to access and use the
                Services.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">1.8. Payment obligations</h3>
              <p className="italic mt-4">Payment Terms</p>
              <p>
                For Customers that purchase our Services, fees are specified at
                the Services interface ‘checkout’ and in the Order Form(s) – and
                must be paid in advance. Payment obligations are non-cancellable
                and, except as expressly stated in the Contract, fees paid are
                non-refundable. For clarity, in the event that the Customer
                downgrades any subscriptions from a paid subscription to a free
                subscription, the Customer will remain responsible for any
                unpaid fees under the paid subscription, and Services under the
                paid subscription will be deemed fully performed and delivered
                upon expiration of the term of the initial paid subscription.
                Fees are stated exclusive of any taxes, levies, duties or
                similar governmental assessments of any nature, including, for
                example, value-added, sales, use or withholding taxes,
                assessable by any jurisdiction (collectively, ‘Taxes’). The
                Customer will be responsible for paying all Taxes associated
                with its purchases, except for those taxes based on our net
                income. Should any payment for the Services be subject to
                withholding tax by any government, the Customer will reimburse
                us for such withholding tax.
              </p>
              <p className="italic mt-4">Free Trial</p>
              <p>
                Baseline may, at its sole discretion, offer a Subscription with
                a free trial for a limited period of time ("Free Trial"). If the
                free trial expires access to features will be suspended and the
                Service will be limited to View only. View only means that edits
                to data already inputted into Service becomes disabled. This
                also includes that the user cannot add new data, for example to
                People, Project, Clients, etc. If you wish to continue the use
                of the Services Customer must upgrade to a paid plan, where you
                will hereby authorize us to charge your credit card or other
                payment method for continued use of the paid Service under such
                Subscription. You may then however, cancel your Subscription in
                accordance with the Subscription section of these Terms.
                <br />
                <br />
                Access to view only will be available for a minimum of 6 months
                after free trial expiration. You acknowledge and agree that we
                may delete all of your User Content or data associated with the
                Workspace that was assigned to your Free Trial.
                <br />
                <br />
                At any time and without notice, Baseline reserves the right to
                (a) modify the terms and conditions of any Free Trial offer, (b)
                cancel any Free Trial offer, or (c) cancel any Free Trial
                subscription at any time.
              </p>
              <p className="italic mt-4">Fees and Fee Changes</p>
              <p>
                Baseline, in its sole discretion and at any time, may modify the
                Subscription fees for the Subscriptions. Any Subscription fee
                change will become effective at the end of the then-current
                Billing Cycle.
                <br />
                <br />
                Baseline will provide you with a reasonable prior notice of any
                change in Subscription fees to give you an opportunity to
                terminate your Subscription before such change becomes
                effective.
                <br />
                <br />
                Your continued use of the Service after the Subscription fee
                change comes into effect constitutes your agreement to pay the
                modified Subscription fee amount.
                <br />
                <br />
                Fees are stated exclusive of any taxes or similar governmental
                assessments of any nature, including, for example, value-added,
                sales, use or withholding taxes, assessable by any jurisdiction
                (collectively, “Taxes”). You will be responsible for paying all
                Taxes associated with your Subscription, except for those taxes
                based on our net income. Should any payment for the Service be
                subject to withholding tax by any government, you will reimburse
                us for such withholding tax.
              </p>
              <p className="italic mt-4">Refunds</p>
              <p>
                Payment obligations are non-cancelable, and fees paid are
                non-refundable and there are no credits for partially used
                Subscription periods.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">
                1.9. Communications from Baseline
              </h3>
              <p>
                By creating an account on our Service, you agree to subscribe to
                newsletters or marketing materials and other promotional
                information we may send. However, you may opt out of receiving
                any, or all, of these marketing communications from us by
                following the unsubscribe link or instructions provided in any
                email we send. Please note that we may still send you
                transactional or administrative messages related to the Service
                even after you have opted out of receiving marketing
                communications.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">1.10. Accounts</h3>
              <p>
                You are responsible for maintaining the confidentiality of your
                account and password, including but not limited to the
                restriction of access to your computer and/or account. You agree
                to accept responsibility for any and all activities or actions
                that occur under your account and/or password, whether your
                password is with our Service or a third-party service. You must
                notify us immediately upon becoming aware of any breach of
                security or unauthorized use of your account.
                <br />
                <br />
                You may not use as a username the name of another person or
                entity that is not lawfully available for use, or a name or
                trademark that is subject to any rights of another person or
                entity other than you, without appropriate authorization. You
                may not use as a username any name that is offensive, vulgar or
                obscene.
                <br />
                <br />
                You may not use the Service if you are 16 years of age or
                younger. By using the Service, you represent and warrant that
                you are over the age of 16.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">1.11. Baseline responsibilities</h3>
              <p className="italic mt-4">Providing the services</p>
              <p>
                The Customer isn’t the only one with responsibilities: we have
                some, too. We will (a) make the Services available to Customer
                and its Authorised Users as described in the Contract; and (b)
                not use or process Customer Data for any purpose without the
                Customer’s prior written instructions; provided, however, that
                ‘prior written instructions’ will be deemed to include use of
                the Services by Authorised Users and any processing related to
                such use or otherwise necessary for the performance of the
                Contract.
              </p>
              <p className="italic mt-4">Protecting customer data</p>
              <p>
                The protection of Customer Data is a top priority for us, so we
                will maintain administrative, physical and technical safeguards
                at a level not materially less protective than as described in
                our security practices page. Those safeguards will include
                measures for preventing unauthorised access, use, modification,
                deletion and disclosure of Customer Data by our personnel.
                Before sharing Customer Data with any of our third-party service
                providers, we will ensure that the third party maintains, at a
                minimum, reasonable data practices for maintaining the
                confidentiality and security of Customer Data and preventing
                unauthorised access. The Customer (not us) bears sole
                responsibility for adequate security, protection and backup of
                Customer Data when it is in the Customer’s or its
                representatives’ or agents’ possession or control. We are not
                responsible for what the Customer’s Authorised Users do with
                Customer Data. That is the Customer’s responsibility.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">1.12. Intellectual Property</h3>
              <p>
                The Service and its Content (excluding User Content), features
                and functionality are and will remain the exclusive property of
                Baseline and its licensors. The Service is protected by
                copyright, trademark, and other laws of both Japan and foreign
                countries. Our trademarks and trade dress may not be used in
                connection with any product or service without the prior written
                consent of Baseline.
                <br />
                <br />
                Definitions: For purposes of these Terms: (i) “Content” means
                text, graphics, images, music, software, audio, video, works of
                authorship of any kind, and information or other materials that
                are posted, generated, provided or otherwise made available
                through the Service; and (ii) “User Content” means any Content
                that account holders (including you) provide to be made
                available through the Service.
              </p>
            </div>
          </div>
          <div className="my-8">
            <h2 className="text-xl font-semibold my-8">
              2. User Terms Perspective
            </h2>
            <p>
              These User Terms of Service (the “User Terms”) govern your access
              and use of our online workplace productivity and employee success
              platform (the “Services”). Please read them carefully. Even though
              you are signing into an existing workspace, these User Terms apply
              to you as a user of the Services. Thanks for using Baseline.
              <br />
              <br />
              These User Terms are a legally binding contract between you and
              us. As part of these User Terms, you agree to comply with the most
              recent version of our Acceptable Use Policy, which is incorporated
              by reference into these User Terms. If you access or use the
              Services, or continue accessing or using the Services after being
              notified of a change to the User Terms or the Acceptable Use
              Policy, you confirm that you have read, understand and agree to be
              bound by the User Terms and the Acceptable Use Policy. “We”, “our”
              and “us” currently refers to the applicable Baseline entity in the
              Contract (defined below).
            </p>
            <p className="italic my-4">
              “Authorised User” of a workspace controlled by a “Customer”
            </p>
            <p>
              An organisation or other third party that we refer to in these
              User Terms as the “Customer” has invited you to a workspace (i.e.
              a unique domain where a group of users may access the Services).
              If you are joining one of your employer’s workspaces, for example,
              the Customer is your employer. If you are joining a workspace
              created by your friend using her personal email address to work on
              her new startup idea, she is our Customer and she is authorising
              you to join her workspace.
              <br />
              <br />
              The Customer has separately agreed to our Customer Terms of
              Service or entered into a written agreement with us (in either
              case, the “Contract”) that permitted the Customer to create and
              configure a workspace so that you and others could join (each
              invitee granted access to the Services, including you, is an
              “Authorised User”). The Contract contains our commitment to
              deliver the Services to the Customer, who may then invite
              Authorised Users to join its workspace(s). When an Authorised User
              (including you) submits content or information to the Services,
              such as messages or files (“Customer Data”), you acknowledge and
              agree that the Customer Data is owned by the Customer and the
              Contract provides the Customer with many choices and control over
              that Customer Data. For example, the Customer may provide or
              withdraw access to the Services, enable or disable third-party
              integrations, manage permissions, retention and export settings,
              transfer or assign workspaces, share projects or consolidate your
              workspace or projects with other workspaces, and these choices and
              instructions may result in the access, use, disclosure,
              modification or deletion of certain or all Customer Data. Please
              contact us at hello@baselineteams.com for more information on our
              different Service subscriptions and the options available to the
              Customer.
            </p>
            <div className="my-4">
              <h3 className="text-xl my-4">
                2.1. The relationship between the User, the Customer and Us
              </h3>
              <p>
                AS BETWEEN US AND THE CUSTOMER, YOU AGREE THAT IT IS SOLELY THE
                CUSTOMER’S RESPONSIBILITY TO (A) INFORM YOU AND ANY AUTHORISED
                USERS OF ANY RELEVANT CUSTOMER POLICIES AND PRACTICES AND ANY
                SETTINGS THAT MAY IMPACT THE PROCESSING OF CUSTOMER DATA; (B)
                OBTAIN ANY RIGHTS, PERMISSIONS OR CONSENTS FROM YOU AND ANY
                AUTHORISED USERS THAT ARE NECESSARY FOR THE LAWFUL USE OF
                CUSTOMER DATA AND THE OPERATION OF THE SERVICES; (C) ENSURE THAT
                THE TRANSFER AND PROCESSING OF CUSTOMER DATA UNDER THE CONTRACT
                IS LAWFUL; AND (D) RESPOND TO AND RESOLVE ANY DISPUTE WITH YOU
                AND ANY AUTHORISED USER RELATING TO OR BASED ON CUSTOMER DATA,
                THE SERVICES OR THE CUSTOMER’S FAILURE TO FULFIL THESE
                OBLIGATIONS. BASELINE MAKES NO REPRESENTATIONS OR WARRANTIES OF
                ANY KIND, WHETHER EXPRESS OR IMPLIED, TO YOU RELATING TO THE
                SERVICES, WHICH ARE PROVIDED TO YOU ON AN “AS IS” AND “AS
                AVAILABLE” BASIS.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">2.2. Rules</h3>
              <p className="italic mt-4">Legal age</p>
              <p>
                To the extent prohibited by applicable law, the Services are not
                intended for and should not be used by anyone under the age of
                16. You represent that you are over the legal age and are the
                intended recipient of the Customer’s invitation to the Services.
                You may not access or use the Services for any purpose if either
                of the representations in the preceding sentence is not true.
                Without limiting the foregoing, you must be of legal working
                age.
              </p>
              <p className="italic mt-4">Acceptable Use</p>
              <p>
                To help ensure a safe and productive work environment, all
                Authorised Users must comply with our Acceptable Use Policy and
                any applicable policies established by the Customer. If you see
                inappropriate behaviour or content, please report it to your
                Primary Owner or employer.
              </p>
              <p className="italic mt-4">
                Your access is in the hands of the Customer
              </p>
              <p>
                These User Terms remain effective until the Customer’s
                subscription for you expires or terminates, or your access to
                the Services has been terminated by the Customer or us. Please
                contact the Customer if you at any time or for any reason wish
                to terminate your account, including due to a disagreement with
                any updates to these User Terms or the Acceptable Use Policy.
              </p>
            </div>
          </div>
          <div className="my-8">
            <h2 className="text-xl font-semibold my-8">
              3. General Customer & User Terms
            </h2>
            <div className="my-4">
              <h3 className="text-xl my-4">3.1. Limitation of liability</h3>
              <p>
                If we believe that there is a violation of the Contract, User
                Terms, Customer Terms the Acceptable Use Policy or any of our
                other policies that can simply be remedied by the Customer’s
                removal of certain Customer Data or taking other action, we
                will, in most cases, ask the Customer to take action rather than
                intervene. We may directly step in and take what we determine to
                be appropriate action (including disabling your account) if the
                Customer does not take appropriate action or we believe there is
                a credible risk of harm to us, the Services, Authorised Users or
                any third parties. IN NO EVENT WILL YOU OR WE HAVE ANY LIABILITY
                TO THE OTHER PARTY FOR ANY LOST PROFITS OR REVENUES OR FOR ANY
                INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER OR PUNITIVE
                DAMAGES HOWEVER CAUSED, WHETHER IN CONTRACT, TORT OR UNDER ANY
                OTHER THEORY OF LIABILITY, AND WHETHER OR NOT THE PARTY HAS BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. UNLESS YOU ARE ALSO
                A CUSTOMER (AND WITHOUT LIMITATION TO OUR RIGHTS AND REMEDIES
                UNDER THE CONTRACT), YOU WILL HAVE NO FINANCIAL LIABILITY TO US
                FOR A BREACH OF THESE USER TERMS. OUR MAXIMUM AGGREGATE
                LIABILITY TO YOU FOR ANY BREACH OF THE USER TERMS IS ONE
                THOUSAND JAPANESE YEN (JPY1000) IN AGGREGATE. THE FOREGOING
                DISCLAIMERS WILL NOT APPLY TO THE EXTENT PROHIBITED BY
                APPLICABLE LAW AND DO NOT LIMIT EITHER PARTY’S RIGHT TO SEEK AND
                OBTAIN EQUITABLE RELIEF.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">3.2. Application of consumer law</h3>
              <p>
                Baseline is a workplace tool intended for use by businesses and
                organisations and not for consumer purposes. To the maximum
                extent permitted by law, you hereby acknowledge and agree that
                consumer laws do not apply. If however any consumer laws do
                apply and cannot otherwise be lawfully excluded, nothing in
                these User Terms will restrict, exclude or modify any statutory
                warranties, guarantees, rights or remedies you have and our
                liability is limited (at our option) to the replacement, repair
                or resupply of the Services or the pro-rata refund to the
                Customer of pre-paid fees for your subscription covering the
                remainder of the term.
                <br />
                <br />
                The sections titled “The relationship between You, the Customer
                and Us”, “Limitation of liability” and “Survival” and all of the
                provisions under the general heading “General Customer & User
                Terms” will survive any termination or expiry of the User Terms.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">3.3. Administration</h3>
              <p className="italic">Email contact</p>
              <p>
                Except as otherwise set forth herein, all notices under the User
                Terms will be by email, although we may instead choose to
                provide notice to Authorised Users through the Services. Notices
                to Baseline should be sent to feedback@baselineteams.com, except
                for legal notices, which must be sent to
                legal@baselineteams.com. A notice will be deemed to have been
                duly given (a) the day after it is sent, in the case of a notice
                sent by email; and (b) the same day, in the case of a notice
                sent through the Services. Notices under the Contract will be
                delivered solely to the Customer in accordance with the terms of
                that agreement.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">3.4. Privacy policy</h3>
              <p>
                Please review our Privacy Policy for more information on how we
                collect and use data relating to the use and performance of our
                products.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">3.5. Modifications</h3>
              <p>
                As our business evolves, we may change these User Terms or the
                Acceptable Use Policy. If we make a material change to the User
                Terms or the Acceptable Use Policy, we will provide you with
                reasonable notice prior to the change taking effect either by
                emailing the email address associated with your account or by
                messaging you through the Services. You can review the most
                current version of the User Terms at any time by visiting this
                page, and by visiting the following for the most current
                versions of the other pages that are referenced in these User
                Terms: Acceptable Use Policy and Privacy Policy. Any material
                revisions to these User Terms will become effective on the date
                set forth in our notice and all other changes will become
                effective on the date we publish the change. If you use the
                Services after the effective date of any changes, that use will
                constitute your acceptance of the revised terms and conditions.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">3.6. Waiver</h3>
              <p>
                No failure or delay by either party in exercising any right
                under the User Terms, including the Acceptable Use Policy, will
                constitute a waiver of that right. No waiver under the User
                Terms will be effective unless made in writing and signed by an
                authorised representative of the party being deemed to have
                granted the waiver.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">3.7. Severability</h3>
              <p>
                The User Terms, including the Acceptable Use Policy, will be
                enforced to the fullest extent permitted under applicable law.
                If any provision of the User Terms is held by a court of
                competent jurisdiction to be contrary to law, the provision will
                be modified by the court and interpreted so as best to
                accomplish the objectives of the original provision to the
                fullest extent permitted by law, and the remaining provisions of
                the User Terms will remain in effect.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">3.8. Assignment</h3>
              <p>
                You may not assign any of your rights or delegate your
                obligations under these User Terms, including the Acceptable Use
                Policy, whether by operation of law or otherwise, without the
                prior written consent of us (not to be unreasonably withheld).
                We may assign these User Terms in their entirety (including all
                terms and conditions incorporated herein by reference), without
                your consent, to a corporate affiliate or in connection with a
                merger, acquisition, corporate reorganisation or sale of all or
                substantially all of our assets.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">3.9. Governing law, venue, fees</h3>
              <p>
                The User Terms, including the Acceptable Use Policy, and any
                disputes arising out of or related hereto, will be governed
                exclusively by the same applicable governing law of the
                Contract, without regard to conflicts of laws rules or the
                United Nations Convention on the International Sale of Goods.
                The courts located in the applicable venue of the Contract will
                have exclusive jurisdiction to adjudicate any dispute arising
                out of or relating to the User Terms, including the Acceptable
                Use Policy, or its formation, interpretation or enforcement.
                Each party hereby consents and submits to the exclusive
                jurisdiction of such courts. In any action or proceeding to
                enforce rights under the User Terms, the prevailing party will
                be entitled to recover its reasonable costs and legal fees.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">3.10. Indemnification</h3>
              <p>
                You agree to defend, indemnify and hold harmless Baseline and
                its licensee and licensors, and their employees, contractors,
                agents, officers and directors, from and against any and all
                claims, damages, obligations, losses, liabilities, costs or
                debt, and expenses (including but not limited to attorney's
                fees), resulting from or arising out of (a) your use and access
                of the Service, by you or any person using your account and
                password; (b) a breach of these Terms; or (c) your User Content
                provided to the Service.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-xl my-4">3.11. Disclaimer</h3>
              <p>
                YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS
                PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. THE SERVICE IS
                PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                NON-INFRINGEMENT OR COURSE OF PERFORMANCE.
                <br />
                <br />
                BASELINE, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS LICENSORS DO
                NOT WARRANT THAT (A) THE SERVICE WILL FUNCTION UNINTERRUPTED,
                SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (B) ANY
                ERRORS OR DEFECTS WILL BE CORRECTED; (C) THE SERVICE IS FREE OF
                VIRUSES OR OTHER HARMFUL COMPONENTS; OR (D) THE RESULTS OF USING
                THE SERVICE WILL MEET YOUR REQUIREMENTS.
              </p>
            </div>
          </div>
          <div className="my-8">
            <h2 className="text-xl font-semibold my-8">
              4. Baseline Acceptable Use Policy
            </h2>
            <p>
              This Acceptable Use Policy sets out a list of acceptable and
              unacceptable conduct for our Services. If we believe a violation
              of the policy is deliberate, repeated or presents a credible risk
              of harm to other users, our customers, the Services or any third
              parties, we may suspend or terminate your access. This policy may
              change as Baseline grows and evolves, so please check here
              regularly for updates and changes. Capitalised terms used below
              but not defined in this policy have the meaning set out in the
              User Terms of Service.
            </p>
            <p className="font-bold mt-4">Do:</p>
            <ul className="list-outside list-disc my-4 ml-4 lg:ml-16">
              <li className="my-2">
                comply with all User Terms of Service, including the terms of
                this Acceptable Use Policy;
              </li>
              <li className="my-2">
                comply with all applicable laws and government regulations,
                including but not limited to all intellectual property, data,
                privacy and export control laws, and regulations promulgated by
                any government agencies, including but not limited to the
                Financial Services Authority and any rules of any national and
                other securities exchanges;
              </li>
              <li className="my-2">
                upload and disseminate only Customer Data to which the Customer
                owns all required rights under law and under contractual and
                fiduciary relationships (such as proprietary and confidential
                information learnt or disclosed as part of employment
                relationships or under non-disclosure agreements) and do so only
                in a manner consistent with applicable law;
              </li>
              <li className="my-2">
                use commercially reasonable efforts to prevent unauthorised
                access to or use of the Services;
              </li>
              <li className="my-2">
                keep passwords and all other login information confidential;
              </li>
              <li className="my-2">
                monitor and control all activity conducted through your account
                in connection with the Services;
              </li>
              <li className="my-2">
                promptly notify us if you become aware of or reasonably suspect
                any illegal or unauthorised activity or a security breach
                involving your accounts or teams, including any loss, theft or
                unauthorised disclosure or use of a username, password or
                account; and
              </li>
              <li className="my-2">
                comply in all respects with all applicable terms of the
                third-party applications, including any that the Customer elects
                to integrate with the Services that you access or subscribe to
                in connection with the Services.
              </li>
            </ul>
            <p className="font-bold mt-4">Do not:</p>
            <ul className="list-outside list-disc my-4 ml-4 lg:ml-16">
              <li className="my-2">
                permit any third party that is not an Authorised User to access
                or use a username or password for the Services;
              </li>
              <li className="my-2">
                share, transfer or otherwise provide access to an account
                designated for you to another person;
              </li>
              <li className="my-2">
                use the Services to store or transmit any Customer Data that may
                infringe upon or misappropriate someone else’s trademark,
                copyright or other intellectual property, or that may be
                tortious or unlawful;
              </li>
              <li className="my-2">
                upload to or transmit from the Services any data, file, software
                or link that contains or redirects to a virus, Trojan horse,
                worm or other harmful component, or a technology that unlawfully
                accesses or downloads content or information stored within the
                Services or on the hardware of Baseline or any third party;
              </li>
              <li className="my-2">
                attempt to reverse engineer, decompile, hack, disable, interfere
                with, disassemble, modify, copy, translate or disrupt the
                features, functionality, integrity or performance of the
                Services (including any mechanism used to restrict or control
                the functionality of the Services), any third-party use of the
                Services or any third-party data contained therein (except to
                the extent such restrictions are prohibited by applicable law);
              </li>
              <li className="my-2">
                attempt to gain unauthorised access to the Services or related
                systems or networks or to defeat, avoid, bypass, remove,
                deactivate or otherwise circumvent any software protection or
                monitoring mechanisms of the Services;
              </li>
              <li className="my-2">
                access the Services in order to build a similar or competitive
                product or service or copy any ideas, features, functions or
                graphics of the Services;
              </li>
              <li className="my-2">
                use the Services in any manner that may harm minors or that
                interacts with or targets people under the age of thirteen;
              </li>
              <li className="my-2">
                engage in activity that incites or encourages violence or hatred
                against individuals or groups;
              </li>
              <li className="my-2">
                impersonate any person or entity including but not limited to an
                employee of ours, an “Administrator”, an “Owner” or any other
                Authorised User, or falsely state or otherwise misrepresent your
                affiliation with a person, organisation or entity;
              </li>
              <li className="my-2">
                use the Services to provide material support or resources (or to
                conceal or disguise the nature, location, source or ownership of
                material support or resources) to any organisation(s) designated
                as a foreign terrorist organisation or other laws and
                regulations concerning national security, defence or terrorism;
              </li>
              <li className="my-2">
                access, search or create accounts for the Services by any means
                other than our publicly supported interfaces (e.g. “scraping” or
                creating accounts in bulk);
              </li>
              <li className="my-2">
                send unsolicited communications, promotions or advertisements,
                or spam;
              </li>
              <li className="my-2">
                place any advertisements within a Baseline client;
              </li>
              <li className="my-2">
                send altered, deceptive or false source-identifying information,
                including “spoofing” or “phishing”;
              </li>
              <li className="my-2">
                abuse referrals or special offers to get more credit than
                deserved;
              </li>
              <li className="my-2">
                sublicense, resell, time-share or similarly exploit the
                Services;
              </li>
              <li className="my-2">
                use the Services for consumer purposes, since Baseline is
                intended for use by businesses and organisations;
              </li>
              <li className="my-2">
                access or use the Services on behalf of, or for the benefit of,
                any patent assertion entity (as defined in the Customer-Specific
                Supplement);
              </li>
              <li className="my-2">
                use contact or other user information obtained from the Services
                (including email addresses) to contact Authorised Users outside
                of the Services without their express permission or authority,
                or to create or distribute mailing lists or other collections of
                contact or user profile information for Authorised Users for use
                outside of the Services; or
              </li>
              <li className="my-2">
                authorise, permit, enable, induce or encourage any third party
                to do any of the above.
              </li>
            </ul>
            <p className="italic my-4">Contacting Baseline</p>
            <p>
              Please also feel free to contact us if you have any questions
              about Baseline’s Acceptable Use Policy. You may contact us at
              hello@baselineteams.com.
            </p>
          </div>
          <div className="my-8">
            <h2 className="text-xl font-semibold my-8">5. Full agreement</h2>
            <p>
              The User Terms, including any terms incorporated by reference into
              the User Terms, constitute the entire agreement between you and us
              and supersede all prior and contemporaneous agreements, proposals
              or representations, written or oral, concerning its subject
              matter. To the extent of any conflict or inconsistency between the
              provisions in these User Terms and any pages referenced in these
              User Terms, the terms of these User Terms will first prevail;
              provided, however, that if there is a conflict or inconsistency
              between the Contract and the User Terms, the terms of the Contract
              will first prevail, followed by the provisions in these User
              Terms, and then followed by the pages referenced in these User
              Terms (e.g. the Privacy Policy). The Customer will be responsible
              for notifying Authorised Users of those conflicts or
              inconsistencies and until such time the terms set forth herein
              will be binding.
            </p>
          </div>
          <div className="my-8">
            <h2 className="text-xl font-semibold my-8">6. Feedback</h2>
            <p>
              We welcome feedback, comments and suggestions for improvements to
              the Service (“Feedback”). You acknowledge and expressly agree that
              any contribution of Feedback does not and will not give or grant
              you any right, title or interest in the Service or in any such
              Feedback. All Feedback becomes the sole and exclusive property of
              Baseline, and Baseline may use and disclose Feedback in any manner
              and for any purpose whatsoever without further notice or
              compensation to you and without retention by you of any
              proprietary or other right or claim. You hereby assign to Baseline
              any and all right, title and interest (including, but not limited
              to, any patent, copyright, trade secret, trademark, show-how,
              know-how, moral rights and any and all other intellectual property
              right) that you may have in and to any and all Feedback.
            </p>
          </div>
          <div className="my-8">
            <h2 className="text-xl font-semibold my-8">
              7. Contacting Baseline
            </h2>
            <p>
              Please also feel free to contact us if you have any questions
              about Baseline’s user terms of service. You may contact us at
              feedback@baselineteams.com
              <br />
              <br />
              Baseline is built by a dedicated international team @N. A. K.
              <br />
              <br />
              N. A. K. <br />
              5-28-12-102 Daizawa, Setagaya-ku, Tokyo 155-0032 <br />
              hello@nakstudios.com
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
}
